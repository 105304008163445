.Logo {
  width: 250px;
  position: fixed;
  top: 20px;
  background: #fff;
  z-index: 999;
  padding: 10px;
  margin-left: -10px;

  img {
    width: 100%;
    height: auto;
  }
}

.Menu {
  margin-top: 130px;
  margin-bottom: 100px;

  @media all and (min-width: 800px){
    position: absolute;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    font-size: 28px;
    margin-top: 30px;
  }

  a {
    color: #000;
    text-decoration: none;
    opacity: 0.7;
    transition: opacity ease-in 50ms;
    font-weight: 500;

    &:hover {
      opacity: 1;
      transition: opacity ease-in 200ms;
    }
  }
}

.LanguageSwitcher {
  margin-bottom: 40px;
  @media all and (min-width: 800px) {
    text-align: right;
    margin-bottom: 20px;
  }

  button {
    background: none;
    border: none;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    text-decoration: none;
    opacity: 0.7;
    transition: opacity ease-in 50ms;
    font-weight: 500;

    &:hover {
      opacity: 1;
      transition: opacity ease-in 200ms;
    }
  }
}
